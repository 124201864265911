.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-size: 10pt;
    padding:5px;
    width: 100%;
    text-align: center;
    color: white;
    background-color: #f76300;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #f76300;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}