.form-login {
    padding: 40px;
}

.br0 {
    border-radius: 0px;
}

.br5 {
    border-radius: 5px;
}

.form-input {
    font-size: 13pt;
}

.btn-kc {
    background-color: #f76300;
    border-color: #f76300;
    text: #fff;
}

.btn-kc:active,
.btn-kc:hover {
    background-color: #f76300;
    border-color: #f76300;
}

.btn-kc-outline {
    background-color: #fff;
    border-color: #f76300;
}

.btn-kc-outline:active,
.btn-kc-outline:hover {
    background-color: #fff;
    border-color: #f76300;
}

.color-kc {
    color: #f76300;
}

.bg-kc {
    background-color: #f76300;
}

.bg-white {
    background-color: #fff;
}

.pt-40 {
    padding-top: 40%;
}

.form-password {
    padding-right: 50px;
}

.ic-fl-rg {
    position: absolute;
    z-index: 9999;
    right: 0;
    padding-right: 10px;
}

.font-10 {
    font-size: 10pt;
}

.font-8 {
    font-size: 8pt;
}

.header {
    padding: 15px 0px 8px 20px;
    background-color: #fff;
    height: 60px;
}

body .bg-color {
    background-color: #f9f9f9;
    min-height: 100vh;
}

.back-color {
    background-color: #f9f9f9;
}

.price-bg {
    background-color: #e6e6e6;
    padding: 0px 10px;
}

.text-grey {
    color: #737373;
}

.fixed-position {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.pt-header {
    padding-top: 210px;
}

.pt-cust {
    padding-top: 125px;
}

.pt-reg {
    padding-top: 70px;
}

.pb-reg {
    padding-bottom: 200px;
}

.pb-detailTx {
    padding-bottom: 100px;
}

.text-dashboard {
    text-align: center;
    color: #808080;
}

.w-90 {
    width: 90%;
}

.img-dashboard {
    width: 100%;
}

.fix-bottom {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
}

.w-100 {
    width: 100%;
}

.btn-kategori {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
}

.modal-item {
    padding-top: 90px;
    height: 95vh;
    overflow: auto;
}

.modal-item-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-ic-fl-rg {
    position: absolute;
    z-index: 9999;
    right: 0;
    padding-right: 10px;
}

.line-through {
    text-decoration: line-through;
}
.modal-item-detail {
    height: 87vh;
    overflow: auto;
}
.modal-item-promo {
    height: 90vh;
    overflow: auto;
}

.white{
    color:#fff;
}
.detail-transaksi {
    background-color: #f76300;
    border-color: #f76300;
    padding:10px;
    margin-top:10px;
    margin-bottom:20px;
    border-radius: 10px;
}